import styled from "styled-components";

export const StyledHeader = styled.header`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 64px;
	background-color: aqua;
	padding: 0 16px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
