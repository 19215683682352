import {StyledNavigation} from "./StyledNavigation";
import {useRecoilValue} from "recoil";
import {navigationStateAtom} from "../atoms/navigationStateAtom";

export const Navigation = () => {
	const navigationState = useRecoilValue(navigationStateAtom);

	return (
		<StyledNavigation menuEnabled={navigationState}>
			{navigationState ? 'on' : 'off'}
		</StyledNavigation>
	);
};

