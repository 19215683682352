import {Header} from "./Header";
import {Main} from "./Main";
import {Navigation} from "./Navigation";
import {StyledLayout} from "./StyledLayout";

export const Layout = () => {
	return (
		<StyledLayout>
			<Header />
			<Navigation />
			<Main />
		</StyledLayout>
	);
};
