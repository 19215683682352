import {RecoilRoot} from "recoil";
import {Layout} from "./Components/Layout";

const App = () => {
  return (
    <RecoilRoot>
      <Layout />
    </RecoilRoot>
  );
}

export default App;
