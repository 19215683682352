import {StyledMain} from "./StyledMain";
import {useRecoilValue} from "recoil";
import {navigationStateAtom} from "../atoms/navigationStateAtom";

export const Main = () => {
	const navigationState = useRecoilValue(navigationStateAtom);

	return (
		<StyledMain>
			{navigationState ? 'on' : 'off'}
		</StyledMain>
	);
};
