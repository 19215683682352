import {StyledHeader} from "./StyledHeader";
import {useRecoilState} from "recoil";
import {navigationStateAtom} from "../atoms/navigationStateAtom";

export const Header = () => {
	const [navigationState, setNavigationStateAtom] = useRecoilState(navigationStateAtom);

	const handleButtonClick = () => {
		setNavigationStateAtom(!navigationState)
	}


	return (
		<StyledHeader>
			<button onClick={handleButtonClick}>
				MENU
			</button>
		</StyledHeader>
	);
};
