import styled from "styled-components";

export const StyledNavigation = styled.nav`
	position: absolute;
	top: 64px;
	left: 0;
	bottom: 0;
	width: 200px;
  transform: translateX(${({menuEnabled}) => menuEnabled ? '0%' : '-100%'});
  transition: transform 300ms ease-in-out 0s;
	background-color: yellow;
`;
