import styled from "styled-components";

export const StyledMain = styled.main`
  position: absolute;
  top: 64px;
  left: 200px;
  bottom: 0;
  right: 0;
  background-color: red;
`;
